<template >
  <div class="">
      <div class="text-center mt-5" v-if="!detail.hascompany">
        <a-row :gutter="[16,16]">
          <a-col :span="24" class="mt-5 font-md">温馨提示:完善企业信息后可获得政策精准匹配</a-col>
          <a-col :span="24" class="mt-3"><a-button type="primary" @click="goCompany()">立即完善</a-button></a-col>
        </a-row>
      </div>

  </div>
</template>
<script>
  import service from '@/service/service'
import { mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      detail:{
        hascompany:0,
      },
    }
  },

  async created() {

    this.getNum();
    this.inviteIndex = setInterval(this.getNum, 60000)
  },
  methods: {
    ...mapActions(['getUserinfo']),



    goCompany(){
      //this.is_rootpath = 0;
      this.$router.push('/work/home/company?addcompany=1')
    },

    async getNum() {
      let res = await service.get_gzt_num();
      // console.log(res);
      this.detail = res.data;
    },

  }
}
</script>
<style lang="less" scoped>

</style>